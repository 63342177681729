import { get } from '@/utils/http';
import qs from 'qs'

export const getLocations = () => {
    const query = qs.stringify({
        populate: ['localizations'],
        pagination: {
            page: 1,
            pageSize: 100,
        },
    },{
        encodeValuesOnly: true,
      })
    return get(`locations?${query}`);
}