<template>
	<div>
		<b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
		<section class="has-background-grey-light category">
			<div class="home">
				<div class="columns is-mobile is-vcentered">
					<div class="column is-2 is-hidden-touch" style="text-align: center">
						<div class="social_networks ml-6" v-if="socialNetworks">
							<div v-for="social in socialNetworks" :key="social.id" class="columns">
								<div @click="openSocialNetwork(social.attributes.redirect_url)" class="column is-4 is-clickable">
									<b-icon :icon="social.attributes.material_design_icon_alias" size="is-small" type="is-primary"> </b-icon>
								</div>
							</div>
							<div class="columns">
								<div @click="shareWebsite()" class="column is-4 is-clickable">
									<b-icon icon="share" size="is-small" type="is-primary"> </b-icon>
								</div>
							</div>
						</div>
					</div>
					<div class="column is-8-desktop is-12-touch" :class="{ 'pl-0 pr-0': !isMobile }">
						<div class="column is-offset-2-desktop is-offset-1-touch">
							<figure v-if="$i18n.locale == 'en'">
								<img :src="back_image_en" />
							</figure>
							<figure v-else>
								<img :src="back_image_pt" />
							</figure>
						</div>
						<div class="info-div">
							<p @click="goBack()" class="has-text-black has-text-weight-light is-size-4-desktop is-size-6-touch is-clickable">
								<b-icon class="round-icon" icon="arrow-left" size="is-small" type="is-green"></b-icon>
							</p>
							<div class="column mb-6 is-5 is-9-mobile">
								<h1 class="info">
									{{ info.attributes.title }}
								</h1>
							</div>

							<!--<div class="columns">-->
							<div class="column is-8-desktop">
								<p class="is-size-3-desktop is-size-4-touch has-text-weight-semibold has-text-black">{{ info.attributes.subtitle }}</p>
							</div>
							<div class="column is-12">
								<div class="columns is-align-items-flex-end">
									<div class="column is-7">
										<p class="is-size-4-desktop is-size-5-touch has-text-black has-text-weight-light">
											{{ info.attributes.description }}
										</p>
									</div>
									<div class="column pb-0">
										<div v-if="hashtags" class="column is-offset-8 is-4 pb-0">
											<p v-for="(hashtag, index) in hashtags" :key="index" class="body-text is-size-6 has-text-weight-bold has-text-right">
												<span class="has-text-lime">#</span>{{ hashtag }}
											</p>
										</div>
									</div>
								</div>
							</div>

							<div class="columns is-mobile is-hidden-desktop mt-6">
								<div class="column is-half is-offset-one-quarter">
									<div class="columns has-text-centered is-mobile">
										<div
											v-for="social in socialNetworks"
											:key="social.id"
											@click="openSocialNetwork(social.attributes.redirect_url)"
											class="column is-clickable"
										>
											<b-icon :icon="social.attributes.material_design_icon_alias" size="is-small" type="is-primary"> </b-icon>
										</div>
										<div @click="shareWebsite()" class="column is-clickable">
											<b-icon icon="share" size="is-small" type="is-primary"> </b-icon>
										</div>
									</div>
								</div>
							</div>
							<!--</div>-->
						</div>
					</div>
				</div>
			</div>
		</section>
		<div class="pb-6">
			<Work
				@childEmitOpenProject="goToProjectPage"
				@childEmit="goToWorkPage"
				v-if="projects"
				:title="$t('views.Category.Work.title')"
				:subtitle="$t('views.Category.Work.subtitle')"
				:link_text="$t('views.Category.Work.link_text')"
				:projects="projects"
			></Work>
		</div>
	</div>
</template>

<script>
//API
import { getCategoryById } from '@/api/categories';
import { getSocialNewtworks } from '@/api/social-networks';

import Work from '@/components/Work.vue';

export default {
	name: 'Category',
	components: {
		Work,
	},
	data() {
		return {
			api_url: process.env.VUE_APP_API_HOST,
			default_image: 'https://buefy.org/static/img/placeholder-1280x960.png',
			test: 0,

			//work_title: 'overview.',
			//work_subtitle: 'Some highlighted projects!',
			info: null,
			projects: null,
			socialNetworks: null,
			isMobile: window.innerWidth <= 1023,
			back_image_en: require('@/assets/img/skills.png'),
			back_image_pt: require('@/assets/img/areas.png'),
			isLoading: true,
			request_categories_done: false,
			request_socials_done: false,
		};
	},
	created() {
		this.getData(this.$i18n.locale);
		this.$watch('$i18n.locale', (new_language) => {
			this.isLoading = true;
			if (this.info.attributes.localizations && this.info.attributes.localizations.data[0]) {
				this.$router.push({ path: `/category/${this.info.attributes.localizations.data[0].id}` });
			}
			this.isLoading = false;
		});

	},
	methods: {
		goToWorkPage() {
			this.$router.push({ path: `/work` });
		},
		goToProjectPage(project_id) {
			this.$router.push({ path: `/project/${project_id}` });
		},
		goBack() {
			this.$router.back();
		},

		openSocialNetwork(link) {
			window.open(link, '_blank');
		},
		shareWebsite() {
			if (navigator.share) {
				navigator
					.share({
						title: 'Formato Verde',
						url: 'https://www.formatoverde.pt/',
					})
					.then(() => {
						console.log('Thanks for sharing!');
					})
					.catch(console.error);
			}
		},
		getData(locale) {
			getCategoryById(this.$route.params.id, locale)
				.then((response) => {
					if (response.data && response.data.data) {
						this.info = response.data.data;
						/*this.title = response.data.data.attributes.title;
					this.subtitle = response.data.data.attributes.subtitle;
					this.description = response.data.data.attributes.description;*/
						this.hashtags = response.data.data.attributes.hashtags.split('\n'),

						this.projects = response.data.data.attributes.projects.data.filter((project) => {
							return project.attributes.on_work_page == true;
						});
					}

					this.request_categories_done = true;
					if (this.request_categories_done && this.request_socials_done) this.isLoading = false;
				})
				.catch((error) => {
					console.log(error);
				});

			getSocialNewtworks()
				.then((response) => {
					if (response.data && response.data.data) {
						this.socialNetworks = response.data.data;
					}
					this.request_socials_done = true;
					if (this.request_categories_done && this.request_socials_done) this.isLoading = false;
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/_variables.scss';
.subject::before {
	margin-top: 10px;
	margin-bottom: 30px;
	display: inline-block;
	content: '';
	color: $lime;
	border-top: 4px solid;
	width: 10rem;
	transform: translateY(-4px);
}

section.category {
	position: relative;
	min-height: 93vh;
}

.body-text {
	font-weight: 300;
	font-size: 20px;
	color: $black;
	letter-spacing: 0px;
	line-height: 30px;
	text-align: left;
}

.info-description {
	font-size: 20px;
	font-weight: 300;
	color: $black;
	letter-spacing: 0px;
	line-height: 30px;
}

.tittle-description {
	font-weight: 300;
	font-size: 15px;
	color: $red;
	letter-spacing: 0px;
	line-height: 22px;
}
/*
.info-div {
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}*/

.border-bottom {
	border-bottom: 4px solid;
}

.bottom-link-div {
	position: absolute;
	bottom: 60px;
	right: 90px;
	font-size: 20px;
	font-weight: 300;
	letter-spacing: 0px;
	line-height: 26px;
}

.bottom-link-div p::before {
	display: inline-block;
	content: '';
	border-top: 1px solid;
	width: 4rem;
	margin: 0 1rem;
	transform: translateY(-4px);
}

.carousel-list.has-shadow {
	box-shadow: none;
}

.title-year {
	font-size: 15px;
}

.home .info {
	color: var(---grey-light);
	text-align: left;
	font-weight: bold;
	letter-spacing: 0px;
	position: relative;
	color: $primary;
	opacity: 1;

	@media only screen and (min-width: 1130px) {
		font-size: 100px;
	}

	@media only screen and (max-width: 1129px) {
		font-size: 85px;
	}

	@media only screen and (max-width: 950px) {
		font-size: 80px;
	}

	@media only screen and (max-width: 900px) {
		font-size: 75px;
	}

	@media only screen and (max-width: 700px) {
		font-size: 65px;
	}

	@media only screen and (max-width: 570px) {
		font-size: 55px;
	}
	@media only screen and (max-width: 450px) {
		font-size: 50px;
	}
	@media only screen and (max-width: 400px) {
		font-size: 45px;
	}
	@media only screen and (max-width: 350px) {
		font-size: 35px;
	}
}

.info-div {
	margin-top: -25%;
}

.round-icon {
	border: 1px solid;
	border-radius: 45px;
	padding: 1rem;
}

.info::after {
	content: '';
	display: block;
	width: 100%;
	border-bottom: 3px solid #24f48c;
	z-index: 100;
}
</style>