<template>
	<section class="hero is-fullheight has-background-primary pt-6">
		<b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
		<Skill @childEmit="goToCategory" v-if="categories" :categories="categories"></Skill>
	</section>
</template>

<script>
import Skill from '@/components/Skill.vue';
//API
import { getCategories } from '@/api/categories';

export default {
	name: 'Skills',
	components: {
		Skill,
	},
	data() {
		return {
			categories: null,
			isLoading: true
		};
	},
	created() {
		this.getData(this.$i18n.locale);
		this.$watch('$i18n.locale', (new_language) => {
			this.isLoading = true;
			this.getData(this.$i18n.locale);
		});
	},
	methods: {
		goToCategory(category_id) {
			this.$router.push({ path: `/category/${category_id}` });
		},
		getData(locale) {
			getCategories(locale)
				.then((response) => {
					if (response.data.data) {
						this.categories = response.data.data;
						this.isLoading = false;
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
};
</script>

<style>
</style>