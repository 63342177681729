import { get } from '@/utils/http';
import qs from 'qs'

export const getCollaborators = () => {
    let query = qs.stringify({
        populate: ['main_image, second_image, localizations'],
        pagination: {
            page: 1,
            pageSize: 100,
        }
    }, {
        encodeValuesOnly: true,
    })
    return get(`collaborators?${query}`);
}